(function () {
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function deleteAllCookies() {
    let cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
  window.deleteAllCookies = deleteAllCookies;
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  if (document.readyState !== 'loading') {
    preinit();
  } else {
    document.addEventListener('DOMContentLoaded', e => {
      preinit();
    });
  }
  function preinit() {
    console.log('Preinit: Waiting for scripts');
    const interval = setInterval(() => {
      if (typeof jQuery !== 'undefined' && typeof anime !== 'undefined') {
        console.log('Preinit complete');
        clearInterval(interval);
        loadJqueryPlugins();
      }
    }, 33);
  }
  function loadJqueryPlugins() {
    console.log('Loading plugins');
    const plugins = [jsPath + 'jquery.waypoints.min.js', 'https://cdnjs.cloudflare.com/ajax/libs/jquery-touch-events/2.0.3/jquery.mobile-events.js'];
    let numberOfScriptsToLoad = plugins.length;
    for (let i = 0; i < plugins.length; i++) {
      (function () {
        const index = i;
        let script = document.createElement('script');
        script.defer = true;
        script.onload = e => {
          console.log('script loaded', plugins[index]);
          numberOfScriptsToLoad--;
          if (numberOfScriptsToLoad <= 0) {
            console.log('Plugins loaded');
            init();
          }
        };
        script.src = plugins[i];
        document.head.appendChild(script);
      })();
    }
  }
  const hash = window.location.hash;
  if (hash) {
    const target = document.getElementById(hash.substring(1));
    if (target) {
      requestIdleCallback(() => {
        window.scrollTo(0, 0);
        $('html, body').animate({
          scrollTop: $(target).offset().top
        }, 400);
      });
    }
  }
  function validateEmail(email) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return true;
    }
    return false;
  }
  function debounce(func) {
    let handle;
    return function (event) {
      if (handle) {
        clearTimeout(handle);
      }
      handle = setTimeout(func, 200, event);
    };
  }
  function throttle(func, delay = 200, options = { leading: false, trailing: true }){
    let timer = null,
        lastRan = null,
        trailingArgs = null;

    return function (...args) {

      if (timer) { //called within cooldown period
        lastRan = this; //update context
        trailingArgs = args; //save for later
        return;
      }

      if (options.leading) {// if leading
        func.call(this, ...args) //call the 1st instance
      } else { // else it's trailing
        lastRan = this; //update context
        trailingArgs = args; //save for later
      }

      const coolDownPeriodComplete = () => {
        if (options.trailing && trailingArgs) { // if trailing and the trailing args exist
          func.call(lastRan, ...trailingArgs); //invoke the instance with stored context "lastRan"
          lastRan = null; //reset the status of lastRan
          trailingArgs = null; //reset trailing arguments
          timer = setTimeout(coolDownPeriodComplete, delay) //clear the timout
        } else {
          timer = null; // reset timer
        }
      }

      timer = setTimeout(coolDownPeriodComplete, delay);
    }
  }

  function hideMenuOnScroll() {
    (function () {
      let lastScrollTop = 0;

      window.addEventListener("scroll", throttle(() => {
        const pos = window.pageYOffset || document.documentElement.scrollTop;

        const speed = pos - lastScrollTop;
        lastScrollTop = pos;

        if (pos > 400) {
          document.querySelector('.header').classList.add("header--condensed");
          document.querySelector('.top-nav__open-hours').classList.add("top-nav__open-hours--condensed");
        } else {
          document.querySelector('.header').classList.remove("header--condensed");
          document.querySelector('.top-nav__open-hours').classList.remove("top-nav__open-hours--condensed");
        }

        if (speed > 100 && pos > 400) {
          document.querySelector('.header').classList.add("header--hidden");
        } else if (speed < -100 || pos < 400) {
          document.querySelector('.header').classList.remove("header--hidden");
        }
      }));
    })();
  }

  function initHamburger() {
    document.querySelector('.hamburger').addEventListener('click', e => {
      e.preventDefault();
      document.querySelector('.mobile-menu').classList.toggle('open');
    });
  }
  function initStandOpen() {
    if (document.querySelector('.open-hours-standalone-title')) {
      document.querySelector('.open-hours-standalone-title').addEventListener('click', e => {
        e.preventDefault();
        document.querySelector('.open-hours-standalone').classList.toggle('open');
      });
    }
  }
  function initAccordions() {
    const elements = document.querySelectorAll('.js-toggle-accordion .handle');
    const allAccordions = document.querySelectorAll('.js-toggle-accordion');
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        element.addEventListener('click', event => {
          event.preventDefault();
          let willOpen = true;
          if (element.closest('.js-toggle-accordion').classList.contains('open')) {
            willOpen = false;
          }
          allAccordions.forEach(el => {
            el.classList.remove('open');
          });
          if (willOpen) {
            element.closest('.js-toggle-accordion').classList.add('open');
          }
        });
      }
    }
  }
  function initAnime(targets = '.logo span') {
    anime({
      targets: targets,
      translateY: [-10, 10],
      delay: anime.stagger(100),
      direction: 'alternate',
      loop: true
    });
  }
  function initWaypoint() {
  }
  function initAsLinks() {
    $('.js-as-link').each(function (index, el) {
      $(el).click(function (e) {
        e.preventDefault();
        window.location.href = $(this).attr('data-href');
      });
      $(el).find('a').click(function (e) {
        e.stopPropagation();
      });
    });
  }
  function initCarousels() {
    // @TODO: add carousel selectors here
    const carousels = $('.carousel-module');
    if (carousels.length > 0) {
      carousels.each((index, el) => {
        let onUpdate = null;
        initCarousel(el, onUpdate, () => {
          const w = document.body.clientWidth;
          const cardWidth = $(el).find('.carousel-item').outerWidth();
          const cardsPerScreen = Math.floor(w / cardWidth);
          return cardsPerScreen;
        });
      });
    }
  }
  function initCarousel(selector, onUpdateFn, getNumCardsPerScreen) {
    const $carousel = $(selector);
    const $carouselInner = $carousel.find('.carousel-inner');
    let cardsPerScreen = getNumCardsPerScreen();
    let lastCardsPerScreen = cardsPerScreen;
    let curPos = 0;
    let numCards = $carousel.find('.carousel-item').length;

    // resize handler
    jQuery(window).resize(function () {
      var n = getNumCardsPerScreen();
      if (n != lastCardsPerScreen) {
        cardsPerScreen = lastCardsPerScreen = getNumCardsPerScreen();
        resetCarousel();
      } else {
        updateCarousel();
      }
    });
    $carousel.find('.button.prev').click(function (e) {
      e.preventDefault();
      if (curPos == 0) {
        return;
      }
      curPos--;
      updateCarousel();
    });
    $carousel.find('.button.next').click(function (e) {
      e.preventDefault();
      if (curPos == numCards - cardsPerScreen) {
        return;
      }
      curPos++;
      updateCarousel();
    });
    if (numCards <= cardsPerScreen) {
      $carousel.find('.button.next').addClass('disabled');
    }
    if ($.isTouchCapable()) {
      $carousel.on('swiperight', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (curPos == 0) {
          return;
        }
        curPos--;
        updateCarousel();
      });
      $carousel.on('swipeleft', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (curPos == numCards - cardsPerScreen) {
          return;
        }
        curPos++;
        updateCarousel();
      });
    }
    function updateCarousel() {
      var step = $carouselInner.find('.carousel-item').outerWidth();
      var translateX = -(step * curPos) | 0;
      var innerWidth = $carouselInner.outerWidth();
      var cardsWidth = numCards * step;
      var maxTranslate = cardsWidth - innerWidth;
      if (translateX < -maxTranslate) {
        translateX = -maxTranslate;
      }
      onUpdateFn && onUpdateFn(curPos + 1);
      $carouselInner.css('transform', 'translateX(' + translateX + 'px)');
      if (curPos == 0) {
        $carousel.find('.button.prev').addClass('disabled');
      } else {
        $carousel.find('.button.prev').removeClass('disabled');
      }
      if (curPos == numCards - cardsPerScreen) {
        $carousel.find('.button.next').addClass('disabled');
      } else {
        $carousel.find('.button.next').removeClass('disabled');
      }
      if (numCards <= cardsPerScreen) {
        $carousel.find('.button.next').addClass('disabled');
      }
    }
    function resetCarousel() {
      curPos = 0;
      updateCarousel();
    }
  }
  function initLocationIndex() {
    let currentFilter = null;
    let allIndexLocationEls = $('.location-index-module a.link');
    $('.location-index-tags a').click(function (e) {
      e.preventDefault();
      const slug = $(this).attr('data-slug');
      $('.location-index-tags a').removeClass('active');
      $(this).addClass('active');
      if (slug == 'all') {
        currentFilter = null;
        $('.location-index-module').removeClass('filtered');
        allIndexLocationEls.show();
        return;
      }
      currentFilter = slug;
      $('.location-index-module').addClass('filtered');
      allIndexLocationEls.each(function (i, el) {
        const tags = $(el).attr('data-tags');
        if (tags && tags.indexOf(currentFilter) > -1) {
          $(el).show();
        } else {
          $(el).hide();
        }
      });
    });
  }
  function initAnnouncement() {
    $('.announcment a.close').click(function (e) {
      e.preventDefault();
      $('body').removeClass('announcment-open');
      setCookie('announcement', window.announcementHash, 7);
    });
    $('.announcment-full a.close').click(function (e) {
      e.preventDefault();
      $('body').removeClass('announcment-full-open');
      setCookie('announcement_full', window.announcementHash, 7);
    });
    $('.announcment-full a.announcement-full-follow').click(function () {
      setCookie('announcement_full', window.announcementHash, 7);
      window.location.href = $(this).attr('href');
    });
  }
  function init() {
    console.log('Init');
    hideMenuOnScroll();
    initAccordions();
    initHamburger();
    initLocationIndex();
    initStandOpen();
    initAnime();
    initWaypoint();
    initAsLinks();
    initCarousels();
    initContactForm();
    initAnnouncement();
    initShowMoreArticles();
    toggleVideoInHero();
  }
  let contactFormMutex = false;
  function initContactForm() {
    const formEl = document.querySelector('.feature-module.contact-edition');
    if (!formEl) {
      return;
    }
    const errorMessage = formEl.querySelector('span.error-message');
    const submitEl = formEl.querySelector('button');
    submitEl.addEventListener('click', function (e) {
      if (contactFormMutex) {
        return;
      }
      e.preventDefault();
      const name = formEl.querySelector('input[name="name"]').value;
      const email = formEl.querySelector('input[name="email"]').value;
      const message = formEl.querySelector('textarea[name="message"]').value;
      const to = formEl.querySelector('input[name="to"]').value;
      console.log(name, email, message);
      if (!validateEmail(email)) {
        formEl.classList.add('error');
        errorMessage.innerHTML = "Du måste ange en giltig e-postadress";
        return;
      }
      if (name == "" || message == "") {
        formEl.classList.add('error');
        errorMessage.innerHTML = "Du måste fylla i alla fält";
        return;
      }
      contactFormMutex = true;
      $.ajax({
        'method': 'POST',
        'url': window.ajaxurl,
        'datatype': 'JSON',
        'data': {
          'action': 'contact_form',
          'to': to,
          'name': name,
          'email': email,
          'message': message
        },
        'cache': false,
        'success': function (res) {
          formEl.classList.add('success');
          errorMessage.innerHTML = "Tack!";
          console.log(res);
        }
      });
    });
  }
  function md5(inputString) {
    var hc = "0123456789abcdef";
    function rh(n) {
      var j,
        s = "";
      for (j = 0; j <= 3; j++) s += hc.charAt(n >> j * 8 + 4 & 0x0F) + hc.charAt(n >> j * 8 & 0x0F);
      return s;
    }
    function ad(x, y) {
      var l = (x & 0xFFFF) + (y & 0xFFFF);
      var m = (x >> 16) + (y >> 16) + (l >> 16);
      return m << 16 | l & 0xFFFF;
    }
    function rl(n, c) {
      return n << c | n >>> 32 - c;
    }
    function cm(q, a, b, x, s, t) {
      return ad(rl(ad(ad(a, q), ad(x, t)), s), b);
    }
    function ff(a, b, c, d, x, s, t) {
      return cm(b & c | ~b & d, a, b, x, s, t);
    }
    function gg(a, b, c, d, x, s, t) {
      return cm(b & d | c & ~d, a, b, x, s, t);
    }
    function hh(a, b, c, d, x, s, t) {
      return cm(b ^ c ^ d, a, b, x, s, t);
    }
    function ii(a, b, c, d, x, s, t) {
      return cm(c ^ (b | ~d), a, b, x, s, t);
    }
    function sb(x) {
      var i;
      var nblk = (x.length + 8 >> 6) + 1;
      var blks = new Array(nblk * 16);
      for (i = 0; i < nblk * 16; i++) blks[i] = 0;
      for (i = 0; i < x.length; i++) blks[i >> 2] |= x.charCodeAt(i) << i % 4 * 8;
      blks[i >> 2] |= 0x80 << i % 4 * 8;
      blks[nblk * 16 - 2] = x.length * 8;
      return blks;
    }
    var i,
      x = sb(inputString),
      a = 1732584193,
      b = -271733879,
      c = -1732584194,
      d = 271733878,
      olda,
      oldb,
      oldc,
      oldd;
    for (i = 0; i < x.length; i += 16) {
      olda = a;
      oldb = b;
      oldc = c;
      oldd = d;
      a = ff(a, b, c, d, x[i + 0], 7, -680876936);
      d = ff(d, a, b, c, x[i + 1], 12, -389564586);
      c = ff(c, d, a, b, x[i + 2], 17, 606105819);
      b = ff(b, c, d, a, x[i + 3], 22, -1044525330);
      a = ff(a, b, c, d, x[i + 4], 7, -176418897);
      d = ff(d, a, b, c, x[i + 5], 12, 1200080426);
      c = ff(c, d, a, b, x[i + 6], 17, -1473231341);
      b = ff(b, c, d, a, x[i + 7], 22, -45705983);
      a = ff(a, b, c, d, x[i + 8], 7, 1770035416);
      d = ff(d, a, b, c, x[i + 9], 12, -1958414417);
      c = ff(c, d, a, b, x[i + 10], 17, -42063);
      b = ff(b, c, d, a, x[i + 11], 22, -1990404162);
      a = ff(a, b, c, d, x[i + 12], 7, 1804603682);
      d = ff(d, a, b, c, x[i + 13], 12, -40341101);
      c = ff(c, d, a, b, x[i + 14], 17, -1502002290);
      b = ff(b, c, d, a, x[i + 15], 22, 1236535329);
      a = gg(a, b, c, d, x[i + 1], 5, -165796510);
      d = gg(d, a, b, c, x[i + 6], 9, -1069501632);
      c = gg(c, d, a, b, x[i + 11], 14, 643717713);
      b = gg(b, c, d, a, x[i + 0], 20, -373897302);
      a = gg(a, b, c, d, x[i + 5], 5, -701558691);
      d = gg(d, a, b, c, x[i + 10], 9, 38016083);
      c = gg(c, d, a, b, x[i + 15], 14, -660478335);
      b = gg(b, c, d, a, x[i + 4], 20, -405537848);
      a = gg(a, b, c, d, x[i + 9], 5, 568446438);
      d = gg(d, a, b, c, x[i + 14], 9, -1019803690);
      c = gg(c, d, a, b, x[i + 3], 14, -187363961);
      b = gg(b, c, d, a, x[i + 8], 20, 1163531501);
      a = gg(a, b, c, d, x[i + 13], 5, -1444681467);
      d = gg(d, a, b, c, x[i + 2], 9, -51403784);
      c = gg(c, d, a, b, x[i + 7], 14, 1735328473);
      b = gg(b, c, d, a, x[i + 12], 20, -1926607734);
      a = hh(a, b, c, d, x[i + 5], 4, -378558);
      d = hh(d, a, b, c, x[i + 8], 11, -2022574463);
      c = hh(c, d, a, b, x[i + 11], 16, 1839030562);
      b = hh(b, c, d, a, x[i + 14], 23, -35309556);
      a = hh(a, b, c, d, x[i + 1], 4, -1530992060);
      d = hh(d, a, b, c, x[i + 4], 11, 1272893353);
      c = hh(c, d, a, b, x[i + 7], 16, -155497632);
      b = hh(b, c, d, a, x[i + 10], 23, -1094730640);
      a = hh(a, b, c, d, x[i + 13], 4, 681279174);
      d = hh(d, a, b, c, x[i + 0], 11, -358537222);
      c = hh(c, d, a, b, x[i + 3], 16, -722521979);
      b = hh(b, c, d, a, x[i + 6], 23, 76029189);
      a = hh(a, b, c, d, x[i + 9], 4, -640364487);
      d = hh(d, a, b, c, x[i + 12], 11, -421815835);
      c = hh(c, d, a, b, x[i + 15], 16, 530742520);
      b = hh(b, c, d, a, x[i + 2], 23, -995338651);
      a = ii(a, b, c, d, x[i + 0], 6, -198630844);
      d = ii(d, a, b, c, x[i + 7], 10, 1126891415);
      c = ii(c, d, a, b, x[i + 14], 15, -1416354905);
      b = ii(b, c, d, a, x[i + 5], 21, -57434055);
      a = ii(a, b, c, d, x[i + 12], 6, 1700485571);
      d = ii(d, a, b, c, x[i + 3], 10, -1894986606);
      c = ii(c, d, a, b, x[i + 10], 15, -1051523);
      b = ii(b, c, d, a, x[i + 1], 21, -2054922799);
      a = ii(a, b, c, d, x[i + 8], 6, 1873313359);
      d = ii(d, a, b, c, x[i + 15], 10, -30611744);
      c = ii(c, d, a, b, x[i + 6], 15, -1560198380);
      b = ii(b, c, d, a, x[i + 13], 21, 1309151649);
      a = ii(a, b, c, d, x[i + 4], 6, -145523070);
      d = ii(d, a, b, c, x[i + 11], 10, -1120210379);
      c = ii(c, d, a, b, x[i + 2], 15, 718787259);
      b = ii(b, c, d, a, x[i + 9], 21, -343485551);
      a = ad(a, olda);
      b = ad(b, oldb);
      c = ad(c, oldc);
      d = ad(d, oldd);
    }
    return rh(a) + rh(b) + rh(c) + rh(d);
  }
  function convert_link_to_relative(url) {
    const domains = ['theory.farstun.com', 'rackfish-websrv04.ad.rackfish.net'];
    for (let j = 0; j < domains.length; j++) {
      if (url.indexOf(domains[j]) > -1) {
        const domainPath = url.split('//');
        const urlPath = domainPath[1].split('/');
        let ret = "";
        for (let i = 1; i < urlPath.length; i++) {
          ret += "/" + urlPath[i];
        }
        return ret;
      }
    }
    return url;
  }
  
  function loadMore(e, pageNumber) {
    const button = e.target || e;
    const url = new URL(window.location.href);
    url.searchParams.set('paged', pageNumber); 

    $.ajax({
        url: window.location + '&paged=' + pageNumber,
        success: function callback(result) {
          
          let items = $(result).find('.js-more-articles').children();
          if (items.length > 0) {
            $('.js-more-articles').append(items);
            $(button).attr('data-page-number', parseInt(pageNumber) + 1);
            
            // Update the browser URL
            window.history.pushState({}, '', url.toString());
          }
        }
    })

    // Check next page for items and disable button if it's empty
    let nextPageNumber = parseInt(pageNumber) + 1;
    $.ajax({
      url: window.location + '&paged=' + nextPageNumber,
      success: function(data) {
        // Page exists
      },
      error: function(xhr, status, error) {
        if (xhr.status === 404) {
          // Page does not exist, disable button
          $(button).attr('disabled', 'disabled');
          $(button).attr('aria-disabled', 'true');
        }
      },
      statusCode: {
        404: function() {
          // Page does not exist, disable button
          $(button).attr('disabled', 'disabled');
          $(button).attr('aria-disabled', 'true');
        }
      }
    });
  };

  function initShowMoreArticles() {
      const loadMoreButton = $('.js-load-more-button');

      $(loadMoreButton).on('click', function loadClick(e) {
          const pageNumber = loadMoreButton.attr('data-page-number');
          loadMore(e, pageNumber);
      });
  };

  function toggleVideoInHero() {

    const mainVideo = $('.js-hero-video-player');
    const videoUrl = mainVideo.attr('data-video-url'); 
    const videoMobileUrl = mainVideo.attr('data-video-mobile-url'); 

    
      if ($(window).width() > 980) {
        mainVideo.append("<source type='video/mp4' src='" + videoUrl + "' />");
      } else {
        mainVideo.append("<source type='video/mp4' src='" + videoMobileUrl + "' />");

      } 
    }
})();
